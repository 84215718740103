import React, { useEffect, useState } from 'react';
import { Box, Link, Heading, Flex, Icon, HStack, Text } from '@chakra-ui/react';
import { getInstagramToken } from '../../functions/firebase/contenuDuSite';

import { ReactComponent as InstagramLogo } from '../../assets/Logo/Trademark avec HL/Asset_Insta_LandingPage.svg';
import { FaInstagram } from 'react-icons/fa';
import axios from 'axios';

const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);
  const [accessToken, setAccessToken] = useState('');

  // 1. Récupération du token depuis Firestore
  useEffect(() => {
    const fetchTokenFromFirebase = async () => {
      try {
        const tokenFromDB = await getInstagramToken();
        if (tokenFromDB) {
          setAccessToken(tokenFromDB);
        } else {
          console.warn('Pas de token Instagram trouvé dans la DB.');
        }
      } catch (err) {
        console.error(
          'Erreur lors de la récupération du token Instagram :',
          err
        );
      }
    };

    fetchTokenFromFirebase();
  }, []);

  // 2. Une fois le token récupéré, on fetch les posts
  useEffect(() => {
    const fetchInstagramPosts = async () => {
      if (!accessToken) return; // évite de faire la requête si le token est vide

      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,thumbnail_url,permalink&access_token=${accessToken}`
        );
        setPosts(response.data.data);
      } catch (error) {
        console.error('Error fetching Instagram posts', error);
      }
    };

    fetchInstagramPosts();
  }, [accessToken]);

  return (
    <Box bg="white" p={10} pb={5}>
      <Flex
        alignItems="center"
        justifyContent={{ base: 'center', md: 'space-between' }}
        direction={{ base: 'column', md: 'row' }}
        gap={4} // Set a small gap for closer elements
      >
        {/* Logo Section */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
          <Box pl={{ base: 0, md: 10 }} mt={{ base: 0, md: 10 }}>
            <InstagramLogo
              width="250px" // Set fixed width for uniform size
              height="250px"
            />
          </Box>
        </Box>

        {/* Instagram Posts Section */}
        <Box bg="white" p={0}>
          {/* Text Section */}
          <Heading
            fontSize="xl"
            textAlign="center"
            mb={5}
            color="#6e6e6e"
            fontFamily="Raleway"
            fontWeight={100}
            maxWidth={{ base: '100%', md: '60%' }}
            mx="auto"
          >
            REJOIGNEZ NOTRE FEED INSTAGRAM
          </Heading>

          {/* Instagram Section */}
          <Flex
            flex="1"
            gap={4}
            alignItems="center"
            justifyContent={{ base: 'center', md: 'flex-end' }}
            flexWrap="wrap"
          >
            {posts.slice(0, 3).map((post, index) => (
              <Link key={post.id} href={post.permalink} isExternal>
                <Box
                  as="img"
                  src={
                    post.media_type === 'VIDEO'
                      ? post.thumbnail_url
                      : post.media_url
                  }
                  alt={`Instagram post ${index + 1}`}
                  width={{ base: '350px', md: '250px' }}
                  height={{ base: '350px', md: '250px' }}
                  objectFit="cover"
                  borderRadius="md"
                  _hover={{ transform: 'scale(1.05)', transition: '0.3s' }}
                />
              </Link>
            ))}
          </Flex>
        </Box>
      </Flex>

      {/* Instagram Handle Link */}
      <HStack justifyContent="flex-end" pt={2} alignItems="center" spacing={2}>
        <Link
          href="https://www.instagram.com/travelgolfclub?igsh=MW5nbXJoMjZjNmo1OA=="
          isExternal
          color="primaryOrange.400"
          fontSize="sm"
          display="flex"
          alignItems="center"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            bg="primaryOrange.400"
            boxSize="20px"
            color="white"
            mr={2}
          >
            <Icon as={FaInstagram} />
          </Box>
          <Text fontFamily={'Raleway'} fontSize={'lg'}>
            @travelgolfclub
          </Text>
        </Link>
      </HStack>
    </Box>
  );
};

export default InstagramFeed;
