// services/contenuDuSite.js

import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

import { db } from '../../firebase';

// On suppose qu'on veut stocker le token Instagram
// dans un document 'instagram' de la collection 'contenuDuSite'.
const contenuDuSiteDocRef = doc(db, 'contenuDuSite', 'instagram');

/**
 * Récupère le token Instagram depuis Firestore
 */
export const getInstagramToken = async () => {
  try {
    const docSnap = await getDoc(contenuDuSiteDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.accessToken || null;
    } else {
      console.warn(
        "Le document 'instagram' n'existe pas dans 'contenuDuSite'."
      );
      return null;
    }
  } catch (error) {
    console.error('Erreur lors de la récupération du token Instagram :', error);
    throw error;
  }
};

/**
 * Met à jour ou crée le token Instagram dans Firestore
 */
export const setInstagramToken = async newToken => {
  try {
    await setDoc(
      contenuDuSiteDocRef,
      {
        accessToken: newToken,
        lastUpdate: serverTimestamp(),
      },
      { merge: true } // fusionne au lieu d'écraser
    );
    return true;
  } catch (error) {
    console.error('Erreur lors de la sauvegarde du token Instagram :', error);
    throw error;
  }
};
